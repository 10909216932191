 
.LeftMenuDrawer{
    width: 280px;
    flex-shrink: 0;
}
  .LeftMenuDrawerPaper {
     width: 260px;
   
}  
.drawerContainer{
    overflow: 'auto';
}
.LeftMenuList{
    padding-top: 0;
}