body{
  margin:0;
  padding: 0;
  background-color: white !important;
}

a{
  color:#1890ff;
  text-decoration: none !important;
}
.toggleButtons{
  margin:10px !important;
  margin-left: 0 !important;
}
.MuiButton-root{
  border-radius: 0 !important;
} 
.MuiIconButton-root, .MuiPaper-rounded {
  border-radius: 0  !important;
} 
.MouseHand, .MuiLink-root{
  cursor: pointer;
}
.whiteContainer{
  background-color: white;
  padding:20px;
  
}
.alignItemsLeft, .alignItemsLeft span{
  text-align: left !important;
  justify-content: left !important;
  
}
.MuiTab-wrapper{
  text-align: left !important;
  justify-content: left !important;
  align-items: left !important;
}
/* .MuiTextField-root{
  padding: 10px;
} */
/* .MuiOutlinedInput-root {
   border-radius: 0 !important;
} */
/* .MuiOutlinedInput-input {
  padding:20px  !important;
}
.MuiAutocomplete-inputRoot{
  padding-bottom:10px  !important;
} */
.MuiFormHelperText-root{
  font-size: 12px;
}
 
.MuiButton-root{
  border-radius: 0 !important;
}
.TopMenuBarUserText{
  text-align: left;
  font-size: 15px;
  line-height: 20px;
  padding-right: 10px;
}
.TopMenuBarMenuPopover{
margin-top:50px;
}
.TopMenuBarUserButton:hover{
  background-color: #0467ca !important;
}
.MuiIconButton-root, .MuiPaper-rounded {
  border-radius: 0  !important;
}
.TopMenuAccountChildMenu{
  width:400px;
  height:250px;
  padding:10px;
}
.TopMenuChildMenu{
  width:400px;
  height:200px;
  padding:10px;
}
.MuiInputBase-root.Mui-disabled{
  color:inherit  !important;
}

.tabTitle{
  margin-left: 15px;
  margin-bottom: 40px;
  margin-top: 20px;
}
.MuiTableCell-head {
line-height: inherit !important;
}
.standardFormInput{
  height: 50px !important;
}
.standardFormInput30{
  height: 30px !important;
  margin-top: 20px;
}

.standardAutocompleteTextField{
  padding-top: 10px !important;
  margin-bottom: 0 !important;
}
.gridAutocompleteTextField{
  padding-top: 20px !important;
  margin-bottom: 10px !important;
}
  
.tableFooterTotals, .tableFooterTotals td {
  font-weight: bold;
  font-size: 14px;
  padding: 5px;
  /* text-align: right; */

}

.auditLogDetailsTableDiv {
  border: 1px solid #dfdede;
}
.auditLogDetailsTableDiv div{
  box-shadow:none !important;
}
.MuiDialog-paperWidthSm {
  max-width: 1000px !important;
}

.ql-tooltip{
  left:5px !important;
}


.MuiListItemIcon-root{
  min-width:2.5rem !important;
}
.MuiTab-root{
  min-width: 10vw !important;
}

.MuiTableCell-root, .MuiTableCell-root button{
  padding: 0.5rem !important;
 /*  vertical-align:top  !important; */
 font-size:0.8rem !important;
}
 
.submitLinkBtn{
text-align: right;
font-size: 16px;
font-weight: bold;
margin-top: 10px;
margin-bottom: 10px;
 
}

.formHelperTooltip{
  font-size: 11px;
}
.featuredCandidateStarSpan{
  vertical-align:middle;
  margin-left: 5px;
 
}
.MuiDialog-container{
  margin-top: 35px !important;
}

@media screen and (max-width: 600px) {
  .hideOnMobile {
      display: none;
  }
}

.showOnMobile {
  display: none !important;
}

@media screen and (max-width: 600px) {
  .showOnMobile {
      display: block !important;
  }
}
 
.tableLinkBtn{
  text-align: right;
  font-weight: 500;
  font-size: 16px;
  margin-top: 10px;
}
.MuiMenu-root{
  z-index: 99999 !important;
}
.MuiListItem-padding{
  padding-top:4px !important;
  padding-bottom:4px !important;
}
.MuiListItem-root {
  padding-right: 0px !important;
}
.footer{
  height: 70px;
}
.Mui-disabled {
 -webkit-text-fill-color: initial !important;
}
.navMenuBtn{
  text-decoration: none;
  color: inherit;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.75 !important;
  letter-spacing: 0.02857em !important;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 8px;
}