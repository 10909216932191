.full-paragraph.align-items-center .title-wrapper {
  width: 100%;
}

.full-paragraph .title-wrapper {
  margin-top: 16px;
  margin-bottom: 16px;
}


.banner .banner-content .banner-text-and-cta .banner-text-and-link .banner-text {
  display: inline;
  font-size: 1rem;
  line-height: 24px;
  text-align: center;
}

 

.paragraph-body.lg {
  font-size: 1.375rem;
  line-height: 40px;
}

.banner {
  height: 72px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

  .banner .banner-content.with-sticky-images .banner-image {
      height: 24px;
  }

.core-title.lg {
  font-size: 2.75rem;
  line-height: 60px;
  font-weight: 700;
}

.additional-button-text {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 18px;
  color: #676879 !important;
}

.price-and-features-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid #d5d5d5;
  margin: 8px 8px;
  border-radius: 4px;
  min-height: 650px;
}

.price-and-features-card .price-card-price-container {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: flex-start;
      -webkit-box-align: flex-start;
      -ms-flex-align: flex-start;
      align-items: flex-start;
      width: 100%;
      padding: 25px;
      min-height:300px;
  }

.price-and-features-card .price-card-price-container .pricing-section {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column;
}

  .price-and-features-card .tier-color-bar {
      width: 100%;
      height: 10px;
      border-radius: 4px 4px 0 0;
  }

.price-card-tier-name {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 24px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 25px;
}

.price-card-price-per-month .plan-price {
  height: 50px;
  font-size: 2.7rem;
  margin-right: 8px;
  font-weight: 300;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;

}

.tier-includes-container {
  padding: 20px;
  font-weight: 400;
  font-size: 16px;
  font-family: Poppins,Roboto,Helvetica,Arial,sans-serif;
   
}

.price-and-features-card .tier-includes-container .tier-includes-title {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 22px;
  margin-top: unset;
}

.price-card-price-per-month .per-user-per-month {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 8px;
  white-space: nowrap;
  text-align: left;
}

.price-card-price-per-month {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

  .price-card-price-per-month .per-user-per-month > span {
      font-weight: 300;
  }

.region {
  padding: 25px;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.price-and-features-card .separator {
  width: 80%;
  margin: 0 10%;
  border: 1px solid #d5d5d5;
  margin-bottom: 10px;
}
.price-card-tier-name .tier-name-extra.with-square-top-text .extra-text {
  padding: 8px;
}
.tier-name-extra {
  font-size: 0.875rem;
  position: relative;
  margin-left: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 4px;
}
.tier-name-extra .extra-text {
  color: #ffffff;
  background-color: #323338;
  padding: 6px 8px;
  border-radius: 4px;
  font-weight: 500;
  -webkit-flex: 0 0;
  -ms-flex: 0 0;
  flex: 0 0;
  word-break: keep-all;
}
.arrow {
  display: inline-block;
  width: 0px;
  height: 0px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 10px solid;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-right-color: #323338;
  position: absolute;
  right: 100%;
}

.btn-purple {
  background-color: #6161FF !important;
}

.btn-blue {
  background-color: rgb(0, 133, 255) !important;
}

.btn-text {
  padding: 16px 32px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 40px;
  border: none;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 17px;
  text-transform: none !important;
}

.btn-text-sm {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 40px;
  border: none;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 17px;
  text-transform: none !important;
  color: white;
}
.tier-description {
  font-size: 16px;
  /*margin: 20px;*/
}
.price-card-tier-feature{
  font-size:14px;
  line-height: 30px;
}
.price-card-tier-feature svg {
  vertical-align: middle;
  margin-left: 5px;
  margin-bottom: 4px;
}
.pricingPlan {
  min-height: 570px;
}
 

.priceDiscountOffer {
  text-decoration: line-through;
}

.checkmark {
  color: darkseagreen;
  margin-right: 5px;
}

.buyButton {
  font-size: 18px !important;
  margin-top: 20px;
}